import React from "react";
import Layout from "../components/Layout";

export default function BlogPage() {
  return (
    <Layout>
      <div>Blog</div>
    </Layout>
  );
}
